/* Blog.css */

/* Blog section styles */
#blog {
    background: #f4f4f9;
    color: #333;
    padding: 60px 20px;
    text-align: center;
}

.blog-title {
    font-size: 3em;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 40px;
    position: relative;
}

.blog-title::after {
    content: '';
    width: 120px;
    height: 5px;
    background: #3498db;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.blog-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.blog-item {
    border-radius: 12px;
    padding: 20px;
    width: 100%;
    max-width: 350px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    margin-bottom: 20px;
    background: linear-gradient(135deg, #ffffff, #f0f0f0);
}

.blog-item:nth-child(1) {
    background: linear-gradient(135deg, #e3ffe7, #d9e7ff);
}

.blog-item:nth-child(2) {
    background: linear-gradient(135deg, #ffefba, #ffffff);
}

.blog-item:nth-child(3) {
    background: linear-gradient(135deg, #ff9a9e, #fad0c4);
}

.blog-item:nth-child(4) {
    background: linear-gradient(135deg, #c2e9fb, #a1c4fd);
}

.blog-item:nth-child(5) {
    background: linear-gradient(135deg, #ffecd2, #fcb69f);
}

.blog-item:nth-child(6) {
    background: linear-gradient(135deg, #d4fc79, #96e6a1);
}

.blog-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-item-title {
    font-size: 1.8em;
    color: #2c3e50;
    margin-bottom: 10px;
}

.blog-item-date {
    font-size: 0.9em;
    color: #999;
    margin-bottom: 15px;
}

.blog-item-excerpt {
    font-size: 1.1em;
    color: #666;
    margin-bottom: 15px;
}

.blog-item-link {
    font-size: 1.1em;
    color: #3498db; /* Reverted to blue color */
    text-decoration: none;
    font-weight: 600;
}

.blog-item-link:hover {
    text-decoration: underline;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.modal-title {
    font-size: 2em;
    margin-bottom: 10px;
    color: #2c3e50;
}

.modal-date {
    font-size: 1.2em;
    color: #999;
    margin-bottom: 20px;
}

.modal-body {
    font-size: 1.1em;
    color: #666;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    background: none;
    border: none;
    cursor: pointer;
    color: #3498db; /* Blue color for the close button */
}

.modal-close:hover {
    color: #2c3e50;
}

/* Responsive design */
@media (max-width: 768px) {
    .blog-list {
        flex-direction: column;
        align-items: center;
    }

    .blog-item {
        max-width: 90%;
    }
}

@media (max-width: 480px) {
    .blog-title {
        font-size: 2.2em;
    }

    .blog-item-title {
        font-size: 1.5em;
    }

    .blog-item-excerpt {
        font-size: 1em;
    }

    .blog-item-link {
        font-size: 1em;
    }
}
