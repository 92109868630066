/* Header.css */
html {
    scroll-behavior: smooth;
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background: linear-gradient(90deg, #6a11cb, #2575fc); /* Gradient background */
    color: #ffffff; /* White text color */
    z-index: 1000;
    transition: background 0.3s ease, box-shadow 0.3s ease;
}

.header:hover {
    background: linear-gradient(90deg, #5a09e2, #1a62d7); /* Darker gradient on hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); /* Enhanced shadow for depth */
}

.logo {
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
}

.logo:hover {
    color: #f2f2f2; /* Very light gray on hover */
}

.nav {
    display: flex;
    align-items: center;
}

.nav-links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-links li {
    margin-left: 20px;
}

.nav-links a {
    text-decoration: none;
    color: #ffffff; /* White text color */
    font-size: 18px; /* Font size for links */
    padding: 10px 15px; /* Padding around the text */
    border-radius: 5px;
    transition: background 0.3s ease, color 0.3s ease;
    margin-right: 20px; /* Reduced spacing for a more compact look */
}

.nav-links a:hover,
.nav-links a.active {
    background-color: #ff61e2; /* Coral color for hover and active state */
    color: #ffffff; /* White text color */
    transform: translateY(-2px); /* Slight lift effect on hover */
}

.menu-icon {
    display: none;
    font-size: 24px;
    cursor: pointer;
    color: #ffffff; /* White icon color */
    transition: color 0.3s ease;
}

.menu-icon:hover {
    color: #f2f2f2; /* Very light gray on hover */
}

/* Responsive styles */
@media (max-width: 768px) {
    .nav {
        position: absolute;
        top: 60px;
        right: 0;
        background: linear-gradient(90deg, #6a11cb, #2575fc); /* Gradient background for mobile */
        width: 100%;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        display: none;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); /* Enhanced shadow for mobile menu */
    }

    .nav.active {
        display: flex;
    }

    .nav-links {
        flex-direction: column;
        width: 100%;
    }

    .nav-links li {
        margin: 10px 0;
    }

    .menu-icon {
        display: block;
    }
}
