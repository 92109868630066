    /* Section.css */
    /* Home Section Styling */
    .home-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px 20px;
        background: linear-gradient(135deg, #f0f9ff, #e0f2f1); /* Soft gradient background */
        color: #333;
        min-height: 100vh;
        border-radius: 15px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
        text-align: center; /* Center align text for better readability */
    }

    /* Home Content Styling */
    .home-content {
        max-width: 80%;
        padding: 20px;
    }

    /* Title Styling */
    .home-title {
        font-size: 2.8em;
        font-weight: 700;
        color: #004d40; /* Deep teal color */
        margin-bottom: 20px;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    }

    /* Subtitle Styling */
    .home-subtitle {
        font-size: 1.6em;
        color: #00796b; /* Teal color */
        margin-bottom: 30px;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    }

    /* Description Styling */
    .home-description {
        font-size: 1.1em;
        line-height: 1.6;
        color: #555;
        margin-bottom: 30px;
    }

    /* Call-to-Action Buttons Styling */
    .cta-buttons {
        display: flex;
        justify-content: center;
        gap: 15px;
    }

    .btn {
        display: inline-block;
        padding: 12px 24px;
        text-decoration: none;
        border-radius: 6px;
        font-size: 1em;
        font-weight: bold;
        transition: all 0.3s ease;
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
        text-align: center;
    }

    .btn-primary {
        background-color: #004d40; /* Deep teal color */
        color: #fff;
        border: 2px solid #004d40;
    }

    .btn-primary:hover {
        background-color: #00332b; /* Darker teal color */
        border-color: #00332b;
    }

    .btn-secondary {
        background-color: #00796b; /* Teal color */
        color: #fff;
        border: 2px solid #00796b;
        margin-top: px;
    }

    .btn-secondary:hover {
        background-color: #004d40; /* Deep teal color */
        border-color: #004d40;
    }

    /* Blockchain Features Section Styling */
    .blockchain-features {
        width: 100%;
        padding: 60px 20px;
        text-align: center;
    }

    .features-title {
        font-size: 2.5em;
        color: #004d40; /* Deep teal color */
        margin-bottom: 30px;
    }

    /* Features Container Styling */
    .features-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 25px;
    }

    /* Feature Box Styling */
    .feature-box {
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
        padding: 25px;
        width: 22%;
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .feature-box:hover {
        transform: translateY(-10px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    }

    /* Feature Box Color Variations */
    .feature-box-1 {
        background: linear-gradient(135deg, #e0f2f1, #b2dfdb);
    }

    .feature-box-2 {
        background: linear-gradient(135deg, #b9fbc0, #a1e4d2);
    }

    .feature-box-3 {
        background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    }

    .feature-box-4 {
        background: linear-gradient(135deg, #c2e9fb, #a1c4fd);
    }

    .feature-box-5 {
        background: linear-gradient(135deg, #e0f2f1, #a1e4d2);
    }

    .feature-box-6 {
        background: linear-gradient(135deg, #b2dfdb, #a1c4fd);
    }

    .feature-box-7 {
        background: linear-gradient(135deg, #a1e4d2, #c2e9fb);
    }

    .feature-box-8 {
        background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    }

    /* Feature Box Heading Styling */
    .feature-box h3 {
        font-size: 1.3em;
        color: #004d40; /* Deep teal color */
        margin-bottom: 10px;
    }

    /* Feature Box Paragraph Styling */
    .feature-box p {
        font-size: 1em;
        color: #555;
        line-height: 1.6;
    }

    /* Responsive styles */
    @media (max-width: 768px) {
        .feature-box {
            width: 45%; /* Adjust width for smaller screens */
        }
    }

    @media (max-width: 480px) {
        .feature-box {
            width: 90%; /* Adjust width for even smaller screens */
        }
    }


    /* Section.css */
#about {
    background: linear-gradient(135deg, #f4f4f9, #e0f7fa);
    color: #333;
    padding: 80px 20px;
    text-align: center;
}

.about-header {
    margin-bottom: 40px;
    background: linear-gradient(135deg, #f9f9f9, #e0f7fa);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.about-title {
    font-size: 3em;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
}

.about-title::after {
    content: '';
    width: 120px;
    height: 5px;
    background: #3498db;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.about-intro {
    font-size: 1.25em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
    text-align: center;
    font-style: italic;
}

.about-mission,
.about-values {
    margin-bottom: 40px;
}

.section-heading {
    font-size: 2.4em;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
}

.section-heading::after {
    content: '';
    width: 100px;
    height: 5px;
    background: #3498db;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
}

.about-mission p,
.about-values p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
    margin: 0 auto;
    max-width: 800px;
    text-align: left;
}

.values-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.value-item {
    border-radius: 12px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    margin-bottom: 20px;
    background: linear-gradient(135deg, #fff, #f0f0f0);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.value-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.value-item-1 {
    background: linear-gradient(135deg, #e3ffe7, #d9e7ff);
}

.value-item-2 {
    background: linear-gradient(135deg, #ffefba, #ffffff);
}

.value-item-3 {
    background: linear-gradient(135deg, #ff9a9e, #fad0c4);
}

.value-item-4 {
    background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
}

.value-item-5 {
    background: linear-gradient(135deg, #ffecd2, #fcb69f);
}

.value-item-6 {
    background: linear-gradient(135deg, #d4fc79, #96e6a1);
}

.value-item h3 {
    font-size: 1.6em;
    color: #3498db;
    margin-bottom: 10px;
}

.value-item p {
    font-size: 1em;
    color: #666;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .values-list {
        flex-direction: column;
        align-items: center;
    }

    .value-item {
        max-width: 80%;
        margin-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .about-title {
        font-size: 2.2em;
    }

    .section-heading {
        font-size: 2em;
    }

    .about-intro {
        font-size: 1em;
    }

    .about-mission p,
    .about-values p {
        font-size: 1em;
    }

    .value-item h3 {
        font-size: 1.3em;
    }

    .value-item p {
        font-size: 0.9em;
    }
}

    /* Section.css */

    #services {
        background: linear-gradient(135deg, #f0f4f8, #e0e0e0);
        color: #333;
        padding: 80px 20px;
        text-align: center;
    }
    
    .services-content {
        max-width: 1200px;
        margin: 0 auto;
    }
    
    .services-title,
    .blockchain-types-title,
    .languages-title {
        font-size: 3.5em;
        font-weight: 700;
        color: #333;
        margin-bottom: 30px;
        position: relative;
    }
    
    .services-title::after,
    .blockchain-types-title::after,
    .languages-title::after {
        content: '';
        width: 120px;
        height: 5px;
        background: #3498db;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .services-intro,
    .blockchain-types-intro,
    .languages-intro {
        font-size: 1.25em;
        line-height: 1.6;
        color: #555;
        margin-bottom: 50px;
        padding: 0 20px;
    }
    
    .services-list,
    .blockchain-types-list,
    .languages-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }
    
    .service-item,
    .blockchain-type-item,
    .language-item {
        border-radius: 15px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 100%;
        max-width: 300px;
        text-align: left;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        position: relative;
        color: #333;
    }
    
    .service-item-1 {
        background: linear-gradient(135deg, #ffadad, #ffdae5);
    }
    
    .service-item-2 {
        background: linear-gradient(135deg, #ffd6a5, #ffe6a7);
    }
    
    .service-item-3 {
        background: linear-gradient(135deg, #fdffb6, #fff7d6);
    }
    
    .service-item-4 {
        background: linear-gradient(135deg, #caffbf, #e0ffe7);
    }
    
    .service-item-5 {
        background: linear-gradient(135deg, #9bf6ff, #d0f4ff);
    }
    
    .service-item-6 {
        background: linear-gradient(135deg, #a0c4ff, #d6e4ff);
    }
    
    .blockchain-type-item-1 {
        background: linear-gradient(135deg, #ff9a9e, #fad0c4);
    }
    
    .blockchain-type-item-2 {
        background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    }
    
    .language-item-1 {
        background: linear-gradient(135deg, #fbc2eb, #a6c0fe);
    }
    
    .language-item-2 {
        background: linear-gradient(135deg, #fdcbf1, #e6dee9);
    }
    
    .language-item-3 {
        background: linear-gradient(135deg, #ff9a9e, #ffdde1);
    }
    
    .language-item-4 {
        background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    }
    
    .language-item-5 {
        background: linear-gradient(135deg, #fbc2eb, #a6c0fe);
    }
    
    .language-item-6 {
        background: linear-gradient(135deg, #fdcbf1, #e6dee9);
    }
    
    .service-item:hover,
    .blockchain-type-item:hover,
    .language-item:hover {
        transform: translateY(-10px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    }
    
    .service-title,
    .blockchain-type-title,
    .language-title {
        font-size: 1.8em;
        font-weight: 600;
        color: #2c3e50;
        margin-bottom: 10px;
        position: relative;
    }
    
    .service-title::after,
    .blockchain-type-title::after,
    .language-title::after {
        content: '';
        width: 50px;
        height: 4px;
        background: #3498db;
        position: absolute;
        bottom: -5px;
        left: 0;
    }
    
    .service-description,
    .blockchain-type-description {
        font-size: 1.1em;
        line-height: 1.6;
        color: #555;
        margin-top: 15px;
    }
    
    .service-item p,
    .blockchain-type-item p {
        margin: 0;
    }
    
    .service-item ul,
    .blockchain-type-item ul {
        list-style-type: disc;
        padding-left: 20px;
    }
    
    .service-item ul li,
    .blockchain-type-item ul li {
        font-size: 1.1em;
        line-height: 1.6;
        color: #555;
    }
    
    .blockchain-logo,
    .language-logo {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        vertical-align: middle;
    }
    

    /* Responsive design */
    
    @media (max-width: 768px) {
        .services-list,
        .blockchain-types-list,
        .languages-list {
            flex-direction: column;
            align-items: center;
        }
    
        .service-item,
        .blockchain-type-item,
        .language-item {
            max-width: 90%;
        }
    }
    
    @media (max-width: 480px) {
        .services-title,
        .blockchain-types-title,
        .languages-title {
            font-size: 2.5em;
        }
    
        .services-intro,
        .blockchain-types-intro,
        .languages-intro {
            font-size: 1em;
        }
    
        .service-title,
        .blockchain-type-title,
        .language-title {
            font-size: 1.5em;
        }
    
        .service-description,
        .blockchain-type-description {
            font-size: 1em;
        }
    }

    .projects {
        margin-top: 80px;
    }
    
    .projects-title {
        font-size: 3.5em;
        font-weight: 700;
        color: #333;
        margin-bottom: 30px;
        position: relative;
    }
    
    .projects-title::after {
        content: '';
        width: 120px;
        height: 5px;
        background: #3498db;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .projects-intro {
        font-size: 1.25em;
        line-height: 1.6;
        color: #555;
        margin-bottom: 50px;
        padding: 0 20px;
    }
    
    .projects-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }
    
    .project-item {
        border-radius: 15px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 100%;
        max-width: 300px;
        text-align: left;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        position: relative;
        color: #333;
    }
    
    .project-item-1 {
        background: linear-gradient(135deg, #ffefba, #ffffff);
    }
    
    .project-item-2 {
        background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    }
    
    .project-item-3 {
        background: linear-gradient(135deg, #ff9a9e, #fad0c4);
    }
    
    .project-item-4 {
        background: linear-gradient(135deg, #c2e9fb, #a1c4fd);
    }
    
    .project-item-5 {
        background: linear-gradient(135deg, #fbc2eb, #a6c0fe);
    }
    
    .project-item-6 {
        background: linear-gradient(135deg, #e0f7fa, #b9fbc0);
    }
    
    .project-item-7 {
        background: linear-gradient(135deg, #f1f8e9, #dcedc8);
    }
    
    .project-item:hover {
        transform: translateY(-10px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    }
    
    .project-title {
        font-size: 1.8em;
        font-weight: 600;
        color: #2c3e50;
        margin-bottom: 10px;
        position: relative;
    }
    
    .project-title::after {
        content: '';
        width: 50px;
        height: 4px;
        background: #3498db;
        position: absolute;
        bottom: -5px;
        left: 0;
    }
    
    .project-description {
        font-size: 1.1em;
        line-height: 1.6;
        color: #555;
        margin-top: 15px;
    }
    
    .project-description ul {
        list-style-type: disc;
        padding-left: 20px;
    }
    
    .project-description ul li {
        font-size: 1.1em;
        line-height: 1.6;
        color: #555;
    }
    
    /* Responsive design for projects section */
    @media (max-width: 768px) {
        .projects-list {
            flex-direction: column;
            align-items: center;
        }
    
        .project-item {
            max-width: 90%;
        }
    }
    
    @media (max-width: 480px) {
        .projects-title {
            font-size: 2.5em;
        }
    
        .projects-intro {
            font-size: 1em;
        }
    
        .project-title {
            font-size: 1.5em;
        }
    
        .project-description {
            font-size: 1em;
        }
    }
    
    
/* Contact.css */

#contact {
    background: linear-gradient(135deg, #f5f7f9, #c3cfe2); /* Light gradient */
    color: #333;
    padding: 60px 20px;
    text-align: center;
}

.contact-content {
    max-width: 800px;
    margin: 0 auto;
}

.contact-title {
    font-size: 3em;
    font-weight: 700;
    color: #3a3a3a; /* Dark grey */
    margin-bottom: 20px;
}

.contact-intro {
    font-size: 1.15em;
    line-height: 1.8;
    color: #555;
    margin-bottom: 40px;
    padding: 0 15px;
}

.contact-form {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin: 0 auto;
    max-width: 500px;
    border: 2px solid #4a90e2; /* Blue border */
    position: relative;
    margin-bottom: 40px;
}

.form-title {
    font-size: 1.8em;
    color: #4a90e2; /* Blue color */
    margin-bottom: 20px;
    border-bottom: 2px solid #4a90e2; /* Blue bottom border */
    padding-bottom: 10px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 1.1em;
    color: #333;
    margin-bottom: 6px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
}

.form-group textarea {
    resize: vertical;
}

.btn-primary {
    display: inline-block;
    padding: 12px 24px;
    text-decoration: none;
    border-radius: 8px;
    font-size: 1.1em;
    font-weight: bold;
    background-color: #4a90e2; /* Blue color */
    color: #fff;
    border: 2px solid #4a90e2;
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #357abd; /* Darker blue */
    border-color: #357abd;
    transform: scale(1.05);
}

.contact-info {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.1em;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.info-title {
    font-size: 1.6em;
    color: #4a90e2; /* Blue color */
    margin-bottom: 20px;
    border-bottom: 2px solid #4a90e2; /* Blue bottom border */
    padding-bottom: 10px;
}

.contact-info p {
    margin: 10px 0;
    line-height: 1.6;
}

.contact-info p strong {
    color: #4a90e2; /* Blue color for emphasis */
}

/* Responsive design */

@media (max-width: 768px) {
    .contact-form {
        padding: 20px;
    }

    .form-title {
        font-size: 1.5em;
    }

    .contact-info {
        font-size: 1em;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .contact-title {
        font-size: 2em;
    }

    .contact-intro {
        font-size: 0.9em;
    }

    .form-title {
        font-size: 1.3em;
    }
}
    